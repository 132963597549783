@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@font-face {
  font-family: "VCR";
  src: local("VCR"), url(./assets/fonts/VCR_OSD_MONO.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body :not(input):not(textarea):not([contenteditable="true"]) {
  user-select: auto !important;
  -webkit-user-select: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  text-decoration: none;
}
